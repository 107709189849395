import { Injectable } from '@angular/core';
import { Job } from '../models/job.model';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { AppConfigService } from './app-config.service';
import { catchError, filter, first, map, switchMap, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, forkJoin, Observable, of, throwError } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { Pagination } from '../models/pagination.interface';
import { NavigationStart, Router, RouterEvent } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class SavedJobsService {

  private _savedJobsTotal$: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  get savedJobsTotal$(): Observable<number> {
    return this._savedJobsTotal$.asObservable();
  }

  updateSavedJobsTotal(savedJobs: Job[]): void {
    this._savedJobsTotal$.next(savedJobs.length);
  }

  get savedJobs(): Job[] {
    return JSON.parse(localStorage.getItem('savedJobs'));
  }

  set savedJobs(savedJobs: Job[]) {
    localStorage.setItem('savedJobs', JSON.stringify(savedJobs));
  }

  constructor(
    private http: HttpClient,
    private configService: AppConfigService,
    private translateService: TranslateService,
    private router: Router
  ) {
    this.setSavedJobs();
  }

  setSavedJobs(): void {
    if (!this.savedJobs) {
      this.savedJobs = [];
      return;
    }

    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationStart && this.checkRoutesToShowNavigationContainer(event.url)),
        first(),
        switchMap(() => this.checkIfSavedJobsAreActive())
      )
      .subscribe();
  }

  checkIfSavedJobsAreActive(): Observable<Job[]> {
    const request$ = [];
    let savedJobs = this.savedJobs;

    savedJobs
      .forEach(({guid}: Job) => {
        request$.push(this.getJob(guid));
      });

    return forkJoin(request$)
      .pipe(
        tap((jobs: Job[]) => {
          const inactiveJobsGuids = [];

          jobs
            .forEach(({status, guid}: Job) => {
              if (status === 'inactive') {
                inactiveJobsGuids.push(guid);
              }
            });

          if (inactiveJobsGuids.length) {
            savedJobs = savedJobs
              .filter(({guid}: Job) => !inactiveJobsGuids.includes(guid));
          }

          this.savedJobs = savedJobs;
          this.updateSavedJobsTotal(this.savedJobs);
        })
      );
  }

  getJob(guid: string): Observable<Partial<Job>> {
    return this.http.get(`${environment.job}/${guid}`)
      .pipe(
        map(({data}: Pagination<Job>) => data[0]),
        catchError((error: HttpErrorResponse) => {
          const { errors } = error.error;
          if (errors.job === 'Job is not active anymore.' || errors === 'Entity not found.') {
            return of({guid, status: 'inactive'});
          }

          return throwError(() => error);
        })
      );
  }

  checkRoutesToShowNavigationContainer(url: string): boolean {
    return !(url.includes('/quiz')                ||
             url.includes('/video')               ||
             url.includes('quiz/message')         ||
             url.includes('application-complete') ||
             url.includes('application-start')    ||
             url.includes('job-details')          ||
             url.includes('/universal-job-update')
    );
  }

  toggleJob(job: Job): void {
    const savedJobs = this.savedJobs;
    const savedJobIndex = this.savedJobs.findIndex((savedJob: Job) => savedJob.id === job.id);

    if (savedJobIndex > -1) {
      savedJobs.splice(savedJobIndex, 1);
    } else {
      job.savedLocally = true;
      savedJobs.push(job);
    }

    this.savedJobs = savedJobs;
    this.updateSavedJobsTotal(savedJobs);
  }

  mapSavedJobs(jobs: Job[]): void {
    this.savedJobs
      .forEach((savedJob: Job) => {
        const locallySavedJob = jobs.find((job: Job) => {
          return job.id === savedJob.id;
        });

        if (locallySavedJob) {
          locallySavedJob.savedLocally = true;
        }
      });
  }
}
